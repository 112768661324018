import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Page non trouvé</h1>
    <p>Cette page n'existe pas, retourner à <a href="https://creasanit.com/">l'accueil</a>.</p>
  </Layout>
)

export default NotFoundPage
